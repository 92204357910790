import allSlots from "@theme/icons/fill/all_slots.inline.svg";
import arrowBack from "@theme/icons/fill/arrow_back.inline.svg";
import arrowNext from "@theme/icons/fill/arrow_next.inline.svg";
import arrowUp from "@theme/icons/fill/arrow_up.inline.svg";
import arrowDown from "@theme/icons/fill/arrow_down.inline.svg";
import attention1 from "@theme/icons/fill/attention_1.inline.svg";
import attention2 from "@theme/icons/fill/attention_2.inline.svg";
import attention3 from "@theme/icons/fill/attention_3.inline.svg";
import questions from "@theme/icons/fill/questions.inline.svg";
import calendar from "@theme/icons/fill/calendar.inline.svg";
import checkInput from "@theme/icons/fill/check_input.inline.svg";
import check from "@theme/icons/fill/check.inline.svg";
import closeInput from "@theme/icons/fill/close_input.inline.svg";
import close from "@theme/icons/fill/close.inline.svg";
import deposit from "@theme/icons/fill/deposit.inline.svg";
import dropdownDown from "@theme/icons/fill/dropdown_down.inline.svg";
import dropdownLeft from "@theme/icons/fill/dropdown_left.inline.svg";
import dropdownRight from "@theme/icons/fill/dropdown_right.inline.svg";
import dropdownUp from "@theme/icons/fill/dropdown_up.inline.svg";
import fullscreen from "@theme/icons/fill/fullscreen.inline.svg";
import info1 from "@theme/icons/fill/info_1.inline.svg";
import info2 from "@theme/icons/fill/info_2.inline.svg";
import info3 from "@theme/icons/fill/info_3.inline.svg";
import info4 from "@theme/icons/fill/info_4.inline.svg";
import promoCode from "@theme/icons/fill/promo_code.inline.svg";
import mail from "@theme/icons/fill/mail.inline.svg";
import phone from "@theme/icons/fill/phone.inline.svg";
import chat from "@theme/icons/fill/chat.inline.svg";
import minus from "@theme/icons/fill/minus.inline.svg";
import notAvailable from "@theme/icons/fill/not_available.inline.svg";
import participants from "@theme/icons/fill/participants.inline.svg";
import plus from "@theme/icons/fill/plus.inline.svg";
import prize from "@theme/icons/fill/prize.inline.svg";
import rollUp from "@theme/icons/fill/roll_up.inline.svg";
import safety1 from "@theme/icons/fill/safety_1.inline.svg";
import safety2 from "@theme/icons/fill/safety_2.inline.svg";
import timer from "@theme/icons/fill/timer.inline.svg";
import update from "@theme/icons/fill/update.inline.svg";
import user from "@theme/icons/fill/user.inline.svg";
import visibilityOff from "@theme/icons/fill/visibility_off.inline.svg";
import visibilityOn from "@theme/icons/fill/visibility_on.inline.svg";
import copy from "@theme/icons/fill/copy.inline.svg";
import key from "@theme/icons/fill/key.inline.svg";
import radioButton from "@theme/icons/fill/radio_button.inline.svg";
import radioButtonActive from "@theme/icons/fill/radio_button_active.inline.svg";
import longTap from "@theme/icons/fill/longtap.inline.svg";
import bitcoin from "@theme/icons/fill/bitcoin.inline.svg";
import paymentCard from "@theme/icons/fill/payment_card.inline.svg";
import up from "@theme/icons/fill/up.inline.svg";
import filterOutline from "@theme/icons/fill/filter_outline.inline.svg";
import edit from "@theme/icons/fill/edit.inline.svg";
import legality from "@theme/icons/fill/legality.inline.svg";

export const FeIconConfig: Record<string, string> = {
    allSlots,
    arrowBack,
    arrowNext,
    arrowUp,
    arrowDown,
    attention1,
    attention2,
    attention3,
    questions,
    calendar,
    checkInput,
    check,
    closeInput,
    close,
    deposit,
    dropdownDown,
    dropdownLeft,
    dropdownRight,
    dropdownUp,
    fullscreen,
    info1,
    info2,
    info3,
    info4,
    promoCode,
    mail,
    phone,
    chat,
    minus,
    notAvailable,
    participants,
    plus,
    prize,
    rollUp,
    safety1,
    safety2,
    timer,
    update,
    user,
    visibilityOff,
    visibilityOn,
    copy,
    key,
    radioButton,
    radioButtonActive,
    longTap,
    bitcoin,
    paymentCard,
    up,
    filterOutline,
    edit,
    legality,
};
